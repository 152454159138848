<template>
  <div class="querengoumai1">
    <div class="all-banner">
      <img src="@/assets/banner/banner-科技未来智能互联.jpg" alt=""/>
    </div>
    <!-- 下面订单区域 -->
    <div class="dingdan">
      <!-- 订单 -->
      <p>订单 >> 填写核对订单信息</p>
      <!-- 接货人信息 -->
      <div class="dingdan-shoujianren">
        <!-- 收件人信息 -->
        <div class="shoujianren" v-loading="userAddressLoading">
          <div class="dingdan-shoujianren-xinxi">收件人信息</div>
          <!-- 新增收获地址 -->
          <button
              class="dingdan-shoujianren-xinzeng"
              @click="dialogFormVisible = true"
          >
            新增收货地址
          </button>
          <!-- 弹出框 -->
          <el-dialog
              title="收货地址信息"
              :visible.sync="dialogFormVisible"
              width="800px"
              height="500px"
              :close-on-click-modal="false"
              :destroy-on-close="true"
              center
              :before-close="addressDialogClose"
          >
            <el-form
                :model="form"
                ref="addressForm"
                style="text-align: center"
                :rules="rules"
            >
              <el-form-item
                  label="地址信息："
                  :label-width="formLabelWidth"
                  style="display: inline-block"
                  prop="region_id"
              >
                <el-input v-show="false" v-model="form.region_id"></el-input>
                <Region
                    :regionStyle="regionStyle"
                    ref="RegionVisble"
                    @reginCallBack="reginBack"
                ></Region>
              </el-form-item>

              <el-form-item
                  label="详细信息："
                  :label-width="formLabelWidth"
                  style="display: inline-block"
                  prop="address"
              >
                <el-input
                    v-model="form.address"
                    autocomplete="off"
                    style="width: 400px; font-size: 12px"
                    placeholder="请输入详细地址信息，如道路、门牌号、小区、楼栋号、单元等信息"
                ></el-input>
              </el-form-item>
              <el-form-item
                  label="邮政编码："
                  :label-width="formLabelWidth"
                  style="display: inline-block"
                  prop="zip_code"
              >
                <el-input
                    v-model="form.zip_code"
                    autocomplete="off"
                    type="number"
                    style="width: 400px; font-size: 12px"
                    placeholder="请填写邮编"
                ></el-input>
              </el-form-item>
              <el-form-item
                  label="收货人姓名："
                  :label-width="formLabelWidth"
                  style="display: inline-block"
                  prop="fullname"
              >
                <el-input
                    v-model="form.fullname"
                    autocomplete="off"
                    style="width: 400px; font-size: 12px"
                    placeholder="请填写收货人姓名"
                ></el-input>
              </el-form-item>
              <el-form-item
                  label="收货人电话："
                  :label-width="formLabelWidth"
                  style="display: inline-block"
                  prop="mobile"
              >
                <el-input
                    v-model="form.mobile"
                    autocomplete="off"
                    style="width: 400px; font-size: 12px"
                    placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item style="display: block">
                <el-checkbox
                    v-model="form.is_default"
                    true-label="y"
                    false-label="n"
                    :checked="form.is_default == 'y'"
                    style="position: reative; left: -75px"
                >设置为默认收获地址
                </el-checkbox
                >
              </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
              <button @click="submitAddress">确认</button>
            </div>
          </el-dialog>
        </div>
        <!-- 收件人地址 -->
        <div
            class="dingdan-shoujianren-address"
            v-for="(addressItem, addressIndex) in address"
            :key="addressIndex"
        >
          <input type="radio" name='address_id' :value="addressItem.id" @change="changeAddress(addressItem.id)"
                 :checked="addressItem.is_default=='y'" style="margin-left: 1rem;margin-right:1.5rem">
          <div class="dingdan-shoujianren-name">{{ addressItem.fullname }}</div>
          <div class="dingdan-shoujianren-addresss">
            {{ addressItem.address }}
          </div>
          <div class="dingdan-shoujianren-phone">{{ addressItem.mobile }}</div>
          <!-- 默认地址 -->
          <div class="dingdan-shoujianren-button">
            <button
                v-if="addressItem.is_default == 'n'"
                @click="changeDefaultAddress(addressItem)"
            >
              设为默认地址
            </button>
            <button
                v-else-if="addressItem.is_default == 'y'"
                class="button-blueColor"
            >
              默认地址
            </button>
          </div>

          <!-- 修改 -->
          <div
              class="dingdan-shoujianren-xiugai"
              @click="editAddress(addressItem)"
          >
            修改
          </div>
        </div>
      </div>
      <!-- 商品清单 -->
      <!-- 商品 -->
      <div class="shangpin">
        <!-- 商品清单 -->
        <div class="shangpinqindan">商品清单</div>
        <!-- 商品详情 -->
        <div class="shangpin-detail">
          <!-- 图片列表 -->
          <div class="shangpin-detail-img">
            <img v-lazy="goodsInfo.thumb" alt=""/>
          </div>
          <!-- 文字 -->
          <div class="shangpin-detail-fonts">
            <!-- 文字分为上下大文字和下面介绍 -->
            <div class="shangpin-detail-fonts-top">
              <p>
                {{ goodsInfo.name }}
              </p>
              <ul>
                <li>单价： {{ goodsInfo.price }} 爪币</li>
                <li>数量：{{ buyCount }}</li>
                <li>运费：免邮</li>
              </ul>
            </div>
          </div>
          <!-- 按钮 -->
          <div class="shangpin-detail-button">
            <div class="shangpin-detail-button-span">
              <ul class="span-zongji">
                <li>总计：</li>
                <li>您的爪币：</li>
              </ul>
              <ul class="span-money">
                <li>{{ goodsInfo.price }}</li>
                <li>{{ currentUser.coin }}</li>
              </ul>
            </div>
            <button
                class="button-blue-160"
                v-if="currentUser.coin > goodsInfo.price"
                @click="buy()"
            >
              确认购买
            </button>
            <button class="button-blue-160" v-else>请充值</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getUserAddress,
  createUserAddress,
  updateUserAddress,
} from "@/api/user";
import {goodsInfo} from "@/api/goods";
import {coinBuy} from "@/api/common";
import Region from "@/components/Region/Region";

export default {
  name: "check",
  components: {
    Region,
  },
  data() {
    var checkMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请填写收获人电话"));
      }
      let reg = /^1([0-9])\d{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("手机号格式错误"));
      }
    };
    return {
      currentUser: this.$store.getters["useUserStore/getUserInfo"],
      address: [],
      goodsInfo: {},
      regionStyle: "width: 400px; font-size: 12px",
      userAddressLoading: false,

      btnDiabled: true,
      address_id: 0,
      buyCount: 0,
      dialogFormVisible: false,
      form: {
        /* 地址信息 */
        region_id: "",
        /* 详细地址信息 */
        address: "",
        /* 邮政编码 */
        zip_code: "",
        /* 收货人姓名 */
        fullname: "",
        /* 收货人电话 */
        mobile: "",
        // 是否默认地址
        is_default: "n",
      },
      /* label的宽度 */
      formLabelWidth: "115px",
      //多选框状态
      rules: {
        /* 地址信息 */
        region_id: [{required: true, message: "请选择地", trigger: "blur"}],
        /*详细地址信息  */
        address: [
          {required: true, message: "请输入详细地址", trigger: "blur"},
        ],
        /* 邮政编码 */
        zip_code: [
          {required: true, message: "请输入邮政编码", trigger: "blur"},
        ],
        fullname: [
          {required: true, message: "请输入收货人姓名", trigger: "blur"},
        ],
        mobile: [{validator: checkMobile, trigger: "blur"}],
      },
    };
  },
  created() {
    if (!this.$store.getters["useUserStore/getToken"]) {
      this.$notify({
        type: "error",
        message: "请先登录！",
      });
      this.$redirectUrl.jumpTo('/login')
      return false;
    }
    if (this.$route.params.goodsId > 0) {
      if (this.$route.params.count > 0) {
        this.buyCount = this.$route.params.count;
        this.loadAddress();
        this.loadGoodsInfo();
      } else {
        this.$notify({
          message: "请至少购买一件",
          type: "error",
        });
        this.$router.push({
          path: "/detail/" + this.$route.params.goodsId
        });
      }
    } else {
      this.$notify({
        message: "您还未选择商品",
        type: "error",
      });
      this.$router.go(-1);
    }
  },
  methods: {
    async loadAddress() {
      this.userAddressLoading = true;
      const res = await getUserAddress();
      if (res.statusCode == 200) {
        this.address = res.data;
        this.userAddressLoading = false;
      } else {
        this.$notify({
          message: res.message,
          type: "error",
        });
        this.userAddressLoading = false;
      }
    },
    async loadGoodsInfo() {
      const res = await goodsInfo({id: this.$route.params.goodsId});
      if (res.statusCode == 200) {
        this.goodsInfo = res.data.goods;
      } else {
        this.$notify({
          message: res.message,
          type: "error",
        });
      }
    },
    buy() {
      if (this.btnDiabled) {
        this.btnDiabled = false;
        setTimeout(() => {
          this.btnDiabled = true;
        }, 3000);

        var obj = document.getElementsByName("address_id");
        for (var i = 0; i < obj.length; i++) {
          if (obj[i].checked) {
            this.address_id = obj[i].value
          }
        }
        if (!this.address_id) {
          this.$notify({
            message: "请先设置收货地址!",
            type: "error",
          });
          return;
        }
        this.$confirm("此操作将消费您的爪币, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              let data = {
                coin: this.goodsInfo.price,
                goods_id: this.goodsInfo.id,
                productId: this.$route.params.productId,
                count: this.buyCount,
                address_id: this.address_id,
              };
              this.toBuy(data);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消购买",
              });
            });
      } else {
        this.$notify({
          message: "请勿频繁点击!",
          type: "warn",
        });
        return false;
      }
    },
    async toBuy(data) {
      const res = await coinBuy(data);
      if (res.statusCode == 200) {
        this.$notify({
          message: "购买成功！",
          type: "success",
        });
        let currentUser = this.$store.getters["useUserStore/getUserInfo"]
        currentUser.coin = res.data.coin;
        this.$store.dispatch('useUserStore/setUserInfo', currentUser)
        this.$redirectUrl.jumpTo("/home/coin/record");
      } else {
        this.$notify({
          message: res.message,
          type: "error",
        });
      }
    },
    submitAddress() {
      this.$refs.addressForm.validate((valid) => {
        if (valid) {
          if (!("id" in this.form)) {
            // 没有id添加
            this.createAddress();
          } else {
            this.saveAddress();
          }
          this.$refs.RegionVisble.cleanVal(); // 清理子组件数据
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async createAddress() {
      const res = await createUserAddress(this.form);
      if (res.statusCode == 200) {
        this.$notify({
          message: "添加成功",
          type: "success",
        });
        this.dialogFormVisible = false;
        this.$router.go(0);
        this.form = {};
      } else {
        this.$notify({
          message: res.message,
          type: "error",
        });
      }
    },
    async saveAddress() {
      const res = await updateUserAddress(this.form);
      if (res.statusCode == 200) {
        this.$notify({
          message: "修改成功",
          type: "success",
        });
        this.dialogFormVisible = false;
        this.loadAddress();
        this.form = {};
      } else {
        this.$notify({
          message: res.message,
          type: "error",
        });
      }
    },
    editAddress(item) {
      this.form = item;
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        this.$refs.RegionVisble.checkedVal(item.region_id);
      });
    },
    changeDefaultAddress(item) {
      this.form = item;
      this.form.is_default = "y";
      this.saveAddress();
    },
    reginBack(region) {
      // region.forEach(element => {
      //   this.form.region_id += element + ','
      // });
      this.form.region_id = region[2];
    },
    addressDialogClose() {
      this.$refs.RegionVisble.cleanVal();
      this.form = {};
      this.dialogFormVisible = false;
    },
    changeAddress(id) {
      this.address_id = id
    }
  },
};
</script>

<style lang="less" scoped>
.querengoumai1 {
  background-color: #fff;
}

.all-banner img {
  width: 100%;
  height: 224px;
  margin-top: -20px;
}

.dingdan {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;

  p {
    font-size: 14px;
  }

  .dingdan-shoujianren {
    margin-top: 22px;
    width: 1200px;

    .shoujianren {
      width: 1200px;
      line-height: 36px;
      background: #efefef;
      text-align: center;
      display: flex;
      justify-content: space-between;

      .dingdan-shoujianren-xinxi {
        font-size: 14px;
        margin-left: 20px;
      }

      .dingdan-shoujianren-xinzeng {
        font-size: 14px;
        color: #00a2e6;
        margin-right: 20px;
      }
    }

    .dingdan-shoujianren-address {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #898989;
      height: 72px;
      font-size: 14px;

      input {
        cursor: pointer;
      }

      .dingdan-shoujianren-name {
        margin-left: -80px;
        width: 160px;
        line-height: 32px;
        font-size: 18px;
        color: #474747;
        text-align: left;
      }

      .dingdan-shoujianren-addresss {
        width: 420px;
        height: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #474747;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }

      .dingdan-shoujianren-phone {
        width: 89px;
        font-size: 14px;
        font-family: Arial;
        font-weight: 400;
        color: #474747;
      }

      .dingdan-shoujianren-button {
        width: 160px;
        text-align: center;

        button {
          background-color: transparent;
          width: 160px;
          font-size: 14px;
          color: #575757;
        }

        .button-blueColor {
          color: #00a2e6;
        }
      }

      .dingdan-shoujianren-xiugai {
        margin-right: 46px;
        font-size: 14px;
        width: 35px;
        cursor: pointer;
      }
    }
  }

  .shangpin {
    margin-top: 40px;
    width: 1200px;

    .shangpinqindan {
      width: 1200px;
      line-height: 40px;
      background: #efefef;
      margin-bottom: 10px;
      text-indent: 20px;
    }

    .shangpin-detail {
      width: 1200px;
      height: 160px;
      display: flex;

      .shangpin-detail-img img {
        width: 160px;
        height: 160px;
      }

      /* 文字 */

      .shangpin-detail-fonts {
        margin: 0 100px 0 40px;
        width: 640px;
        height: 160px;
        border-right: 1px solid #575757;

        p {
          width: 594px;
          height: 47px;
          font-size: 18px;
          line-height: 30px;
        }

        ul {
          margin-top: 24px;
          float: left;

          li {
            font-size: 14px;
            color: #575757;
            margin-bottom: 10px;
          }
        }
      }

      /* 按钮 */

      .shangpin-detail-button {
        width: 160px;
        height: 88px;
        margin-top: 40px;
        text-align: center;
        font-size: 14px;

        .shangpin-detail-button-span {
          display: flex;
          justify-content: center;
          margin-left: -10px;
        }

        .span-money li:last-of-type,
        .span-zongji li:last-of-type {
          color: #575757;
          margin: 12px 0 16px 0;
        }

        .span-zongji {
          text-align: right;
        }

        .chongzhi {
          color: #e4007f;
        }
      }
    }
  }

  .dingdan-shoujianren-buttons {
    font-size: 18px;
    font-weight: 500;
    color: #595757;
    width: 184px;
    line-height: 48px;
    text-align: center;
  }
}

/* el-dialog */
div.el-dialog {
  margin: 0 auto !important;
  top: 50%;
  transform: translateY(-50%);
  // border-radius: 10px;
  // width: 500px;
  // height: 500px!important;
  .el-dialog__header {
    background: #f7f7f7;
    text-align: left;
    font-weight: 600;
  }
}

.dialog-footer {
  position: relative;
  top: -40px;

  button {
    width: 220px;
    height: 36px;
    background: #00a2e6;
    font-size: 18px;
    color: #ffffff;
  }
}

.el-radio.is-bordered {
  border-radius: 0;
}

/deep/ .el-input__inner {
  border-radius: 0;
}

// 添加颜色类
.el-radio__input.is-checked + .el-radio__label {
  color: #fd7624 !important;
}

.el-radio__input.is-checked .el-radio__inner {
  background: #fd7624 !important;
  border-color: #fd7624 !important;
}
</style>
