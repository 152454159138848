import request from './ajax'
// 积分支付
export const coinBuy = (data, token) => request({
    method: "post",
    url: "/coinbuy",
    params: data,
    headers:{
        Authorization: 'Bearer ' + token
    }
})


// 上传文件
export const multUpload = (data, token) => request({
    method: "post",
    url: "/upload/multiple",
    data,
    headers:{
        Authorization: 'Bearer ' + token
    }
})


// 评论列表
export const reviewList = (data, token) => request({
    method: "get",
    url: "/reviews",
    params: data,
    headers:{
        Authorization: 'Bearer ' + token
    }
})

export const evaluates = (data) => request({
    method: "get",
    url: "/evaluates",
    params: data
})

export const getBankCodes = (data) => request({
    method: "get",
    url: "/ald/getbankcode",
    params: data
})
